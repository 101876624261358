import React, { useState, useRef, useEffect } from "react";
// import styles from "../../styles/components-styles/faq-accordion/faq-accordion.module.scss";
import "./faq.scss"

function FaqAccordion(props) {
    const content = useRef(null);
    const [active, setActive] = useState(props.accordionExpand ? "active" : "");
    const [height, setHeight] = useState(props.accordionExpand ? "unset" : "0px");
    function toggleAccordion() {
        setActive(active === "" ? "active" : "");
        setHeight(
            active === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }

    useEffect(() => {
        if (props.accordionExpand) {
            setActive("active");
            setHeight("unset");
        } else {
            setActive("");
            setHeight("0px");
        }
    }, [props.accordionExpand]);

    const styles = {
        fontSize: '20px',
        color: "#096ef2",
        fontWeight: "600"
    }

    return (
        <div className={"accordion-section"}>
            <div
                className={"accordion" + " " + `${active}`}
                onClick={() => toggleAccordion()}
            >
                <h2 className={"accordion-title" + " " + `${active}`}>
                    {props.data.query}
                </h2>
                {active ? <div style={styles}>-</div> : <div style={styles}>+</div>}
            </div>
            <div
                ref={content}
                className={"accordion-content"}
                style={{ maxHeight: `${height}` }}
            >
                <p
                    className={"accordion-text"}
                    dangerouslySetInnerHTML={{ __html: props.data.solution }}
                ></p>
                {props.data.bulletPoints
                    ? props.data.bulletPoints.map(function (object, i) {
                        return (
                            <p
                                className={"bullet-point"}
                                dangerouslySetInnerHTML={{ __html: object.text }}
                                key={i}
                            ></p>
                        );
                    })
                    : null}
            </div>
        </div>
    );
}

export default FaqAccordion;
