import React, { useEffect } from "react";
import "./main.scss"
// import SubscribeInput from "../subscribe-input/subscribe-input";
// import Link from "next/link";
// import Image from "next/image";
// import { animateNumbers } from "../../utilities/numberAnimation";

const ProductBanner = (props) => {
    // useEffect(() => {
    //   animateNumbers(".metric-number");
    // }, []);

    return (
        <div className={"banner-main"}>
            {/* <img
        className={`${styles["banner-bg"]} ${styles["bg-curves"]}`}
        src="/images/bg-banners/lines-bg-header.svg"
        alt="banner bg lines"
      /> */}
            <div className={"banner-curve-container"}>
                <img
                    className={`banner-bg bg-curves`}
                    src="/images/bg-banners/lines-bg-header.svg"
                    alt="banner bg lines"
                    layout="responsive"
                    width={1920}
                    height={1080}
                />
            </div>
            {props.data.bannerBgPresent && (
                <div className={"banner-D-container"}>
                    {/* <img
            className={`${styles["banner-bg-indicator"]} ${styles["bg-indicators"]}`}
            src={props.data.bannerBgImageSrc}
            alt="banner bg"
          /> */}
                    <img
                        className={"banner-bg-indicator bg-indicators"}
                        src={props.data.bannerBgImageSrc}
                        alt="banner bg"
                        layout="responsive"
                        width={1920}
                        height={1080}
                    />
                </div>
            )}
            <div className={"left-container"}>
                {props.data.inlineHeading ? (
                    <h1
                        dangerouslySetInnerHTML={{ __html: props.data.inlineHeading }}
                    ></h1>
                ) : (
                    ""
                )}
                {props.data.heading ? (
                    <h1 dangerouslySetInnerHTML={{ __html: props.data.heading }}></h1>
                ) : props.data.headingLight && props.data.headingBold ? (
                    <h1>
                        <span className={"header-light"}>
                            {props.data.headingLight}
                        </span>
                        <br />
                        <span className="header-bold">{props.data.headingBold}</span>
                    </h1>
                ) : (
                    ""
                )}
                {props.data.subHeading ? (
                    <h3 dangerouslySetInnerHTML={{ __html: props.data.subHeading }}></h3>
                ) : (
                    ""
                )}
                <h2 dangerouslySetInnerHTML={{ __html: props.data.tagline }}></h2>
                {/* {props.data.buttonDescription ? (
                    <div className={"button-with-description"}>
                        <a
                            href={props.data.buttonLink}
                            target={props.data.newTab ? "_blank" : ""}
                        >
                            {props.data.buttonText}
                        </a>
                        <p
                            dangerouslySetInnerHTML={{ __html: props.data.buttonDescription }}
                        />
                    </div>
                ) : props.data.buttonText ? (
                    <a
                        href={props.data.buttonLink}
                        target={props.data.newTab ? "_blank" : ""}
                    >
                        {props.data.buttonText}
                    </a>
                ) : (
                    ""
                )} */}
                {/* Span added for animation purposes */}
                {/* {props.data.buttonText ? (
          <Link
            href={props.data.buttonLink}
            target={props.data.newTab ? "_blank" : ""}
          >
            {props.data.buttonText}

            <span></span>
          </Link>
        ) : (
          ""
        )} */}
                {props.data.metrics ? (
                    <div className={"banner-numbers-container"}>
                        {props.data.metricList.map((item, index) => {
                            return (
                                <div className={"banner-numbers-item"} key={index}>
                                    <span className={"highlighted-heading"}>
                                        {item.numberElement ? (
                                            <span className="metric-number">
                                                {item.numberElement}
                                            </span>
                                        ) : null}
                                        {item.textBold}
                                    </span>
                                    &nbsp;{item.textLight}
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                {/* {props.data.subscriptionBox ? (
                    props.data.subscriptionBoxClassName ? (
                        <div className="subscribe-box-input">
                            <SubscribeInput className={props.data.subscriptionBoxClassName} />
                        </div>
                    ) : (
                        <SubscribeInput />
                    )
                ) : (
                    ""
                )} */}
            </div>
            <div className={"right-container"}>
                {props.data.video ? (
                    <video
                        autoPlay="autoplay"
                        loop
                        muted
                        playsInline
                        className={"video-background"}
                        width={700}
                        height={700}
                    >
                        <source src={props.data.imageSrc} type="video/mp4"></source>
                    </video>
                ) : (
                    <img
                        src={props.data.imageSrc}
                        alt={props.data.altText ? props.data.altText : "Decentro"}
                        title={props.data.titleText ? props.data.titleText : ""}
                    />
                )}
            </div>
        </div >
    );
};

export default ProductBanner;
