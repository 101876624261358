import React from "react";
import { Helmet } from "react-helmet";
import "./GrievancePolicy.scss";

const GrievancePolicy = () => (
  <React.Fragment>
    <Helmet>
      <title>Grievance Policy | Decpay</title>
      <link rel="canonical" href="https://decpay.in/grievance/" />
      <meta name="description" content="Grievance Policy | Decpay" />
      <meta name="keywords" content="grievance, policy, decPay" />
      <meta property="og:title" content="Grievance Policy | Decpay" />
      <meta property="og:image" content="/images/banner.svg" />
    </Helmet>
    <div className="grievance-policy">
      <h2>DECFIN Policy for Customer Complaints, Grievances and Resolutions</h2>
      <p>Updated on May 1, 2024</p>
      <div className="content">
        <p>
          <strong>Objective</strong>
          <p>The Grievance Redressal policy is designed where</p>
          <ul className="bullet-stack-dotted">
            <li>
              Merchants and the Merchant’s end-users (collectively “Customers”)
              raise a dispute/complaint with Decfin
            </li>
            <li>Acknowledge disputes/complaints</li>
            <li>Resolve disputes/complaints</li>
          </ul>
        </p>
        <p>
          <strong>Scope</strong>
          <ul className="bullet-stack-dotted">
            <li>Registering a complaint</li>
            <li>Ownership & Escalation Matrix</li>
            <li>Handling complaints and failed transactions</li>
            <li>Managing refunds & chargebacks </li>
          </ul>
        </p>

        <ol>
          <li>
            <strong>Reporting a complaint</strong>
          </li>
          <p>
            Customers can reach out by visiting the Support page of the Decfin
            website. The following modes are available
          </p>
          <ol>
            <li>
              <strong>Form/Portal: </strong>Customers can fill a short form
              detailing their issue & someone from our operations team will get
              in touch with the Customers. Sample form [link]
            </li>
            <li>
              <strong>Email: </strong>Customers can also send an email to
              support@decentro.tech Our operations team will pick up these
              queries and resolve them
            </li>
            <li>
              <strong>Self-serve:</strong>
              <ol type="a">
                <li>
                  Customers can check our FAQ document to find answers to
                  frequently asked questions
                </li>
                <li>
                  For any transaction disputes, Decfin aims to provide a portal
                  where end-users can check the status of their transaction by
                  plugging in the transaction ID
                </li>
              </ol>
            </li>
            <li>
              <strong>Call (Only for merchants): </strong>In case the query can
              be better answered via a phone call, the merchants can call their
              designated account manager for a faster resolution
            </li>
          </ol>

          <p>
            <strong>Timings:</strong>
            <ul>
              <li>
                Our operations team is available from 9am to 9pm during working
                weekdays.
              </li>
              <li>
                For weekends & holidays, the team is available from 11am to 5pm
              </li>
              <li>The support team will be available round the clock.</li>
            </ul>
          </p>

          <p>
            <strong>Turn-around time for Level 1 resolution</strong>
            <p>
              <table>
                <tr>
                  <th>Mode</th>
                  <th>Registration</th>
                  <th>Acknowledgement</th>
                  <th>First response</th>
                  <th>Resolution TAT (max.)</th>
                </tr>
                <tr>
                  <td>Form/Portal</td>
                  <td>Instant</td>
                  <td>Instant (Auto-reply)</td>
                  <td>2 Working days</td>
                  <td>10 Working days</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>Instant</td>
                  <td>Instant (Auto-reply)</td>
                  <td>2 Working days</td>
                  <td>10 Working days</td>
                </tr>
                <tr>
                  <td>Call (Merchants)</td>
                  <td>Office timings</td>
                  <td>Instant</td>
                  <td>2 Working days</td>
                  <td>10 Working days</td>
                </tr>
                <tr>
                  <td>In-person</td>
                  <td>Office timings</td>
                  <td>Instant</td>
                  <td>2 Working days</td>
                  <td>10 Working days</td>
                </tr>
              </table>
            </p>
          </p>
          <br />
          <p>
            <strong>Turn-around time for Level 2 resolution</strong>

            <p>
              If the customer does not receive a response within 10 working days
              of lodging the complaint, the customer can approach the ‘Junior
              Nodal Officer’ of the Company, at the following contact details:
            </p>
            <br />
            <p>
              Junior Nodal Officer
              <br />
              Mr. Shivam Gupta
              <br />
              Assistant Nodal Officer
              <br />
              Decfin Tech Pvt Ltd.
              <br />
              Address: Dongle Desk, Indiranagar, Bengaluru, 560038
              <br />
              Email: grievances@decentro.tech
            </p>
            <br />
            <p>
              The Junior Nodal Officer shall send his/her final response to the
              customer within 20 working days of lodging the complaint. In some
              cases, the Company may seek additional time along with the
              justification for an extension.
            </p>
          </p>

          <br />
          <p>
            <strong>Turn-around time for Level 3 resolution</strong>

            <p>
              If the customer does not receive a response within 20 working days
              of lodging the complaint, the customer can approach the ‘Nodal
              Officer’ of the Company, at the following contact details:
            </p>
            <br />
            <p>
              Nodal Officer
              <br />
              Mr. Pratik Daudkhane
              <br />
              Nodal Officer
              <br />
              Decfin Tech Pvt Ltd.
              <br />
              Address: Dongle Desk, Indiranagar, Bengaluru, 560038
              <br />
              Email: nodal@decentro.tech
            </p>
            <br />
            <p>
              The Nodal Officer shall send his/her final response to the
              customer within 30 working days of lodging the complaint. In some
              cases, the Company may seek additional time along with the
              justification for an extension.
            </p>
            <p>
              <table>
                <tr>
                  <th>Mode</th>
                  <th>Resolution TAT</th>
                </tr>
                <tr>
                  <td>Customer support</td>
                  <td>10 Working days</td>
                </tr>
                <tr>
                  <td>Junior Nodal officer</td>
                  <td>20 Working days</td>
                </tr>
                <tr>
                  <td>Nodal officer</td>
                  <td>30 Working days</td>
                </tr>
              </table>
            </p>
          </p>
          <br />
          <li>
            <strong>Failed transactions</strong>
          </li>
          <p>
            If the customer makes a payment via UPI, IMPS NEFT, or RTGS,
            however, if Decfin is unable to fund the end beneficiary account,
            Decfin will initiate an auto-reversal within T+1 days.
          </p>
          <br />
          <p>
            This is in line with Annex to Circular DPSS.CO.PD
            No.629/02.01.014/2019-20 dated September 20, 2019 -{" "}
            <a
              href="https://m.rbi.org.in/scripts/BS_CircularIndexDisplay.aspx?Id=11693"
              target="_blank"
            >
              https://m.rbi.org.in/scripts/BS_CircularIndexDisplay.aspx?Id=11693
            </a>
          </p>
          <br />
          <p>
            Our respective policy pages -
            <ul>
              <li>
                <a href="https://decpay.in/grievance/" target="_blank">
                  https://decpay.in/grievance/
                </a>
              </li>
              <li>
                <a href="https://decpay.in/terms/" target="_blank">
                  https://decpay.in/terms/
                </a>
              </li>
              <li>
                <a href="https://decpay.in/privacy/" target="_blank">
                  https://decpay.in/privacy/
                </a>
              </li>
            </ul>
          </p>
          <br />
          <li>
            <strong>Managing refunds </strong>
            <ul className="bullet-stack-dotted">
              <li>
                Merchants can initiate refunds on the Decfin dashboard in case
                the order is cancelled or the goods are returned
              </li>
              <li>
                Decfin will settle the refund using the merchant settlement
                balance
              </li>
              <li>
                Decfin is able to use bank transfer methods such as IMPS, UPI,
                and NEFT to process refunds.
              </li>
              <li>
                The timelines to process refunds would depend on the mode of
                payment. In case of IMPS, refunds are instantaneous, whereas in
                case of NEFT/RTGS, the refunds would take at least 30 mins
                depending on the bank’s processing cycles
              </li>
              <li>
                The timelines communicated by merchants to process refunds would
                be T+1 where T is the date when the merchant has requested for a
                refund regarding a transaction
              </li>
            </ul>
          </li>

          <br />
          <li>
            <strong>Managing chargebacks</strong>
            <ul className="bullet-stack-dotted">
              <li>
                In case Customers are not happy with the Merchant resolution
                process, they may initiate a chargeback with their bank
              </li>
              <li>
                Decfin receives this chargeback request from its partner banks
              </li>
              <li>
                Decfin will block the chargeback amount from the merchant’s
                settlement amount
              </li>
              <li>
                Decfin informs the merchant to either approve or reject the
                chargeback with a valid proof of delivery of service
              </li>
              <li>
                Decfin either accepts or rejects the chargeback, and asks the
                merchant to initiate a refund to the consumer
              </li>
              <li>
                Decfin, post this, informs the banking partner of the same
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </React.Fragment>
);

export default GrievancePolicy;
