import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faTwitter,
  faLinkedin,
  faAngellist,
  faProductHunt,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.scss";

const Footer = () => (
  <footer className="site-footer">
    <div className="footer-top">
      <div className="footer-logo">
        <Link to="/">
          <img
            className="decentro-d"
            src="/images/decpay-footer-logo-black.svg"
            alt="Decfin"
          />
        </Link>
      </div>
      <div className="footer-links-section">
        <div className="footer-links">
          <a href="https://docs.decentro.tech/" target="_blank">
            Developers
          </a>
        </div>
        <div className="footer-links">
          <Link to="/grievance/">Grievance Policy</Link>
        </div>
        <div className="footer-links">
          <Link to="/privacy/">Privacy Policy</Link>
        </div>
        <div className="footer-links">
          <Link to="/terms/">Terms & Conditions</Link>
        </div>
        <div className="footer-links">
          <Link to="/agreement/">Master Services Agreement</Link>
        </div>
        <div className="cert-footer-section">
          <div className="backing-and-certificate">
            <img
              className="iso-certificate"
              src="/images/iso-certificate.svg"
              alt="iso-certificate"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="copyright">
      <div className="contact-emails">
        <p className="copyright-text">
          Copyright © 2024 Decfin. All Rights Reserved.
        </p>
        <div className="contacts">
          <div className="contact-email-business email-block">
            <p className="email-label">Business & Partnership</p>
            <label className="email">
              <a href="mailto:hello@decentro.tech" target="_blank">
                hello@decentro.tech
              </a>
            </label>
          </div>
          <div className="contact-email-support email-block">
            <p className="email-label">Support & Payment-related Queries</p>
            <label className="email support-email">
              <a href="mailto:support@decentro.tech" target="_blank">
                support@decentro.tech
              </a>
            </label>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
