import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga";
import ScrollToTop from "./scrollToTop.js";
import Header from "./components/Header/Header.js";
import HeaderMobile from "./components/HeaderMobile/HeaderMobile.js";
import Footer from "./components/Footer/Footer.js";
import Home from "./pages/Home/Home";
import Documents from "./pages/Documents/Documents.js";
import "./App.css";
import Enach from "./pages/Enach/Enach.js";

function App() {
  const [viewType, setViewType] = useState("");
  const pathname = window.location.pathname;

  ReactGA.initialize("UA-167537821-4");

  ReactGA.pageview(window.location.pathname + window.location.search);

  const handleResize = () => {
    if (window.innerWidth < 1024) setViewType("mobile");
    else setViewType("desktop");
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  return (
    <React.Fragment>
      <Router>
        {viewType === "desktop" ? <Header /> : <HeaderMobile />}
        <ScrollToTop />
        <Switch>
          <Route exact strict={true} path="/">
            <Home />
          </Route>
          <Route exact strict={true} path="/terms/">
            <Documents page="terms-and-conditions" />
          </Route>
          <Route exact strict={true} path="/privacy/">
            <Documents page="privacy-policy" />
          </Route>
          <Route exact strict={true} path="/grievance/">
            <Documents page="grievance-policy" />
          </Route>
          <Route exact strict={true} path="/agreement/">
            <Documents page="agreement" />
          </Route>
          <Route exact strict={true} path="/enach/">
            <Enach />
          </Route>
          <Route>
            <Redirect from="*" to="/" />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
