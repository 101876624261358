import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import InlineImageAndParagraph from "../../components/InlineImageAndParagraph/InlineImageAndParagraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faCog,
  faMagic,
  faRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Home.scss";

const Home = () => {
  window.onload = () => setSeparatorHeight();
  window.onresize = () => setSeparatorHeight();
  useEffect(() => {
    setSeparatorHeight();
  }, []);

  const setSeparatorHeight = () => {
    if (document.getElementsByClassName("product")[0]) {
      var bulletPointsHeight =
        document.getElementsByClassName("product")[0].offsetHeight;
      document.getElementsByTagName("hr")[0].style.height =
        bulletPointsHeight + "px";
    }
  };
  const inlineImageAndParagraphData = {
    headingLight: "What Is",
    headingBold: " DecPay?",
    text:
      "Customers want innovation, and businesses want the freedom to innovate. We are simply the bridge to make the want possible - Welcome to Decpay." +
      "<br/><br/>Decpay is a payment aggregator that provides ready-made banking APIs and infrastructure. Businesses can select the desired API modules, test them in the sandbox, integrate with legacy financial institutions, and launch payment products in a matter of weeks!" +
      "<br/><br/>Our API suite also manages all updates and iterations, and the required fixes that may arise, without disrupting the flow.",
    imageSrc: "/images/what-is-decpay.svg",
    altText: "What is DecPay?",
    titleText: "What is DecPay?",
  };

  const scrollToSection = (sectionRef) => {
    var elToScroll = document.querySelector("#" + sectionRef);
    elToScroll.scrollIntoView({ alignToTop: true, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Payment aggregator offering ready-made banking APIs - Decpay
        </title>
        <link rel="canonical" href="https://decpay.in/" />
        <meta
          name="description"
          content="Experience frictionless money flow with Decay’s payment API infrastructure."
        />
        <meta
          name="keywords"
          content="B2B marketplace India, virtual account, nbfc, nbfc India, list of nbfc India, Banking APIs, Business banking, payment APIs, payment aggregator, UPI API, ledger accounts in banking, payment collections, API infrastructure, neo-bank, Business Banking APIs, financial services"
        />
        <meta
          property="og:title"
          content="Payment aggregator offering ready-made banking APIs - Decpay"
        />
        <meta property="og:image" content="/images/home-banner.png" />
      </Helmet>
      <div className="homepage" id="homepage">
        <div className="banner">
          <img
            className="banner-bg"
            src="/images/home-banner-bg.png"
            alt="Decpay banner bg"
          />
          <div className="text-content">
            <h1>
              Powerful API Infrastructure for
              <br />
              <span>Payment Integrations</span>
            </h1>
            <h2>
              Plug and Play Solutions for Payment Collections and Settlements
            </h2>
          </div>
          <div className="image-content">
            <img
              src="/images/home-banner.png"
              alt="Decentro is a full-stack API banking platform for financial integrations."
            />
          </div>
        </div>
        <div className="what-is-decpay">
          <InlineImageAndParagraph data={inlineImageAndParagraphData} />
        </div>
        <div className="our-products">
          <h2>
            Our <span>Products</span>
          </h2>
          <div className="our-products-content">
            <div className="virtual-accounts product">
              <h2 className="product-title">Ledger accounts</h2>
              <div className="virtual-accounts-pointers">
                <div className="virtual-accounts-content products-pointer open-va">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/open-va-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">
                      Open ledger accounts via restful APIs
                    </p>
                    <p className="point-content">
                      Open ledger accounts with account number & UPI IDs in
                      minutes!
                    </p>
                  </div>
                </div>
                <div className="virtual-accounts-content products-pointer payments-req">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/payment-request-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Payment requests are a breeze</p>
                    <p className="point-content">
                      Because you just have to share your virtual account
                      details with customers or vendors. Then use your app,
                      email, or SMS to request and accept payments on the move!
                    </p>
                  </div>
                </div>
                <div className="virtual-accounts-content products-pointer transfer-money">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/send-money-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Transfer Money in a snap</p>
                    <p className="point-content">
                      Because you can use common modes like UPI, IMPS, NEFT, and
                      RTGS to simplify money transfer to ledger accounts.
                    </p>
                  </div>
                </div>
                <div className="virtual-accounts-content products-pointer collections-reconciliation">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/report-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">
                      Hassle-free collections & reconciliation
                    </p>
                    <p className="point-content">
                      Disburse & reconcile payouts in real-time automatically,
                      using Decpay’s ledger accounts APIs. Streamline your
                      bookkeeping!
                    </p>
                  </div>
                </div>
                <div className="virtual-accounts-content products-pointer notiffy-webhooks">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/notify-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Notify customers via Webhooks</p>
                    <p className="point-content">
                      Use ledger accounts APIs to monitor & share transaction
                      status updates with customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="payments-collections product">
              <h2 className="product-title">Payment Collections</h2>
              <div className="payment-collections-pointers">
                <div className="payments-collections-content products-pointer create-upi-links">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/qr-code-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">
                      Create UPI links or dynamic QR codes
                    </p>
                    <p className="point-content">
                      It’s easy sharing with customers, and easier to collect
                      payments. Facilitate peer-to-peer transactions in
                      real-time!
                    </p>
                  </div>
                </div>
                <div className="payments-collections-content products-pointer send-upi-req">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/send-upi-pay-request-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Send UPI payment requests</p>
                    <p className="point-content">
                      You can choose their desired UPI app or any of the
                      available options of NEFT/IMPS/RTGS and diversify your
                      business transactions.
                    </p>
                  </div>
                </div>
                <div className="payments-collections-content products-pointer instant-payments-links">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/link-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Instant Payment Links</p>
                    <p className="point-content">
                      Create and share payment links for transactions with your
                      customers.
                    </p>
                  </div>
                </div>
                <div className="payments-collections-content products-pointer upi-deep-links">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/deep-links-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">Set up UPI Deep-links</p>
                    <p className="point-content">
                      Google Pay or Phone Pe - Choose the app you like – works
                      with them all!
                    </p>
                  </div>
                </div>
                <div className="payments-collections-content products-pointer recur-payments">
                  <div className="step-icon">
                    <img
                      className="step-icon-img"
                      src="/images/automate-icon.svg"
                    />
                  </div>
                  <div className="products-pointer-content">
                    <p className="point-title">
                      Make recurring payments a clockwork{" "}
                    </p>
                    <p className="point-content">
                      Automate recurring payments and subscriptions via UPI &
                      ENACH. The time and effort you save is more than the
                      icing!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="get-started">
          <h2>
            It’s a <span>Revolution!</span>
          </h2>
          <p className="tagline">
            We are here to help you experience frictionless flow of money and
            data!
          </p>
          <div className="get-started-steps">
            <div className="step-block">
              <img src="/images/create-account.svg" />
              <h4>Create an account</h4>
              <p>
                Sign up with your business email; post a simple verification
                process, you are all set to choose your financial modules.
              </p>
            </div>
            <div className="step-arrow">
              <img src="/images/right-arrow.svg" />
            </div>
            <div className="step-block">
              <img src="/images/sandbox.svg" />
              <h4>Integrate in sandbox</h4>
              <p>
                You can explore and integrate with the user-friendly sandbox for
                all your desired modules, right from the word go.
              </p>
            </div>
            <div className="step-arrow">
              <img src="/images/right-arrow.svg" />
            </div>
            <div className="step-block">
              <img src="/images/go-live.svg" />
              <h4>Go live!</h4>
              <p>
                Once you are set with the integrations and want to move to
                production, you can let us know right from your dashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
