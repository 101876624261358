import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';

import './HeaderMobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const HeaderMobile = () => {
	const [click, setClick] = useState(false);

	const closeMobileMenu = () => {
		setClick(false);
		document.getElementsByClassName('dropdown-overlay')[0].classList.remove('overlay');
		document.getElementsByTagName("body")[0].classList.remove("stop-scroll");
	};

	const handleClick = (event) => {
		event.stopPropagation();
		setClick(!click)
		document.getElementsByClassName('dropdown-overlay')[0].classList.toggle('overlay');
		document.getElementsByTagName("body")[0].classList.toggle("stop-scroll");
	}

	return (
		<div>
			<div className="dropdown-overlay">

			</div>
			<header id="header">
				<div className="menu-mobile">
					<div className="head">
						<Link to="/"><img src="/images/decpay-logo-black.svg" alt="Decfin Logo" /></Link>
						<div className="mobile-menu-button" onClick={handleClick}>
							{click ?
								<FontAwesomeIcon icon={faTimes} />
								:
								<FontAwesomeIcon icon={faBars} />
							}
						</div>
					</div>
					<ul id="mobile-menu" className={click ? "active" : ""}>
						<li onClick={closeMobileMenu}><a href="https://docs.decentro.tech/" target="_blank">Developers</a></li>
					</ul>
				</div>
			</header>
		</div>
	)
}

export default HeaderMobile;
