import React from "react";
import { NavLink } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions.js";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.js";
import GrievancePolicy from "./GrievancePolicy/GrievancePolicy.js";
import ServiceAggrement from "./ServiceAggrement/ServiceAggrement.js";
import TextAndButtonBanner from "../../components/TextAndButtonBanner/TextAndButtonBanner.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import requests from "../../requests";
import "./Documents.scss";

function Documents(props) {
  const contactUsData = {
    text: "<span>Can’t find the answer to your question?</span><br/>Contact us and we’ll get back to you as soon as we can.",
    buttonText: "Contact Us",
    buttonLink: "/signup/",
  };

  //   const textAndButtonBannerData = {
  //     text: "<span>Can’t find the answer to your question?</span><br/>Contact us and we’ll get back to you as soon as we can.",
  //     buttonText: "Contact Us",
  //     newTab: false,
  //     buttonLink: requests.SIGNUP,
  //   };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className="documents-container">
      <div className="document-type-list">
        <NavLink to="/grievance/" activeClassName="active">
          Grievance Policy
        </NavLink>
        <NavLink to="/terms/" activeClassName="active">
          Terms & Conditions
        </NavLink>
        <NavLink to="/privacy/" activeClassName="active">
          Privacy Policy
        </NavLink>
        <NavLink to="/agreement/" activeClassName="active">
          Master Services Agreement
        </NavLink>
      </div>
      <div className="document-content">
        {props.page == "grievance-policy" ? (
          <GrievancePolicy />
        ) : props.page == "terms-and-conditions" ? (
          <TermsAndConditions />
        ) : props.page == "privacy-policy" ? (
          <PrivacyPolicy />
        ) : props.page == "agreement" ? (
          <ServiceAggrement />
        ) : (
          <GrievancePolicy />
        )}
        <div className="scroll-to-top-btn">
          <button
            onClick={() => {
              scrollToTop();
            }}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        </div>
      </div>
      {/* <TextAndButtonBanner data={textAndButtonBannerData} /> */}
    </div>
  );
}

export default Documents;
