import React, { useEffect, useState } from "react";
import "./BulletPointsAndTextBlock.scss"


const BulletPointsAndTextBlock = (props) => {
    useEffect(() => {
        setSeparatorHeight();
        window.onresize = () => setSeparatorHeight();
    }, []);

    const setSeparatorHeight = () => {
        if (document.getElementsByClassName("bullet-points")[0]) {
            var bulletPointsHeight =
                document.getElementsByClassName("bullet-points")[0].offsetHeight;
            document.getElementsByTagName("hr")[0].style.height =
                bulletPointsHeight + "px";
        }
    };
    return (
        <div
            className={"bullet-points-text-container"}
            id={props.id ? props.id : ""}
        >
            <h2>
                {props.data.headingLight} <span>{props.data.headingBold}</span>
            </h2>
            {
                props.data.tagline ? (
                    <p className={"tagline"}>{props.data.tagline}</p>
                ) : null
            }
            {
                props.data.bulletPointsHeader ? (
                    <p className={"bullet-points-header"}>
                        {props.data.bulletPointsHeader}
                    </p>
                ) : null
            }
            <div className={"bullet-points-text-content"}>
                <div className={"bullet-points"}>
                    {props.data.bulletPoints.map(function (object, i) {
                        return (
                            <p dangerouslySetInnerHTML={{ __html: object.text }} key={i}></p>
                        );
                    })}
                </div>
                <hr />
                <div className={"text-block"}>
                    {props.data.textInBoxList.map(function (object, i) {
                        return (
                            <div key={i}>
                                <p dangerouslySetInnerHTML={{ __html: object.textInBox }}></p>
                                <a href={object.link} target="_blank">
                                    {object.linkText}
                                    {/* <FaChevronRight /> */}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div >
    );
};

export default BulletPointsAndTextBlock;
