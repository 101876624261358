import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Header.scss';

const Header = (props) => {

	return (
		<header id="header">
			<div className="menu-full">
				<div className="left">
					<Link to="/"><img src="/images/decpay-logo-black.svg" alt="Decfin Logo" /></Link>
				</div>
				<div className="right">
					<ul id="menu">
						<li><a href="https://docs.decentro.tech/" target="_blank">Developers</a></li>
						<li>
							<Link to="/enach/">
								E-nach
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</header >
	)
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
